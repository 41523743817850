import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  searchTerm: "",
  filterWarehouse: "1",
  filterDate: "",
  selectedItem: [],
  tempItems: [],
  precision: {
    1: 0.1,
    2: 0.01,
    3: 0.001,
    4: 0.0001,
  },
};

const itemSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setFilterWarehouse: (state, action) => {
      state.filterWarehouse = action.payload;
    },
    setFilterDate: (state, action) => {
      state.filterDate = action.payload;
    },
    clearSelectedItems: (state) => {
      state.selectedItem = [];
    },
    setSelectedItems: (state, action) => {
      const data = [...current(state.selectedItem)];
      const tempData = [...current(state.tempItems)];
      const dataMap = new Map();
      const duplicates = [];

      data.forEach((item) => {
        if (!dataMap.has(item.itemData.id)) {
          dataMap.set(item.itemData.id, item);
        }
      });

      tempData.forEach((item) => {
        if (!dataMap.has(item.itemData.id)) {
          dataMap.set(item.itemData.id, item);
        } else {
          duplicates.push(item);
        }
      });

      if (duplicates.length > 0) {
        let duplicateItemsName = ``;

        duplicates.forEach((item) => {
          duplicateItemsName += item.itemData.name + "\n";
        });

        if (!action.payload) {
          alert(
            "Duplicate Item(s) found: \n\n" +
              duplicateItemsName +
              "\nDuplicate Item(s) will skiped, if any unique item(s), it will go in!"
          );
        }
      }

      state.selectedItem = [...dataMap.values()];
    },
    setTempItems: (state, action) => {
      state.tempItems = action.payload.map((item) => {
        return {
          itemData: item.item?item.item:item,
          input: {
            count_quantity: item.count_quantity?item.count_quantity:0,
            book_quantity: item.book_quantity?item.book_quantity:0,
            uom_quantity: item.uom_quantity?item.uom_quantity:0,
            uom: item.uom,
            description: item.description ? item.description : "",
          },
        };
      });
    },
    removeItem: (state, action) => {
      const items = [...state.selectedItem];
      items.splice(action.payload, 1);

      state.selectedItem = items;
    },
    updateInputItemData: (state, action) => {
      const { index, fieldName, value } = action.payload;

      const newData = [...state.selectedItem];

      newData[index].input[fieldName] = value;
      
      state.selectedItem = newData;
    },
    resetModal: (state) => {
      state.tempItems = [];
      state.searchTerm = "";
    },
  },
});

export const {
  setSearchTerm,
  setFilterWarehouse,
  setFilterDate,
  setItemList,
  setTempItems,
  resetModal,
  clearSelectedItems,
  setSelectedItems,
  updateInputItemData,
  removeItem,
} = itemSlice.actions;

export default itemSlice.reducer;
