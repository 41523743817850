import { configureStore } from "@reduxjs/toolkit";

import itemReducer from "./slices/itemSlice";
import transactionFieldSlice from "./slices/transactionFieldSlice";
import sidebarSlice from "./slices/sidebarSlice";
import locatorSlice from "./slices/locatorSlice";

const store = configureStore({
  reducer: {
    itemList: itemReducer,
    transactionField: transactionFieldSlice,
    sidebar: sidebarSlice,
    locator: locatorSlice,
  },
});

export default store;
