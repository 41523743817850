import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Provider } from "react-redux";

import CacheBuster from './CacheBuster';
import lazyLoader from "./lazyLoader";

import axios from 'axios'

import LanguageContext from './utils/context/LanguageContext'

import { getData, storeData } from './utils/common'

import './scss/style.scss';

import store from "./redux/store";

const processing = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => lazyLoader(() => import('./containers/TheLayout')));

// Pages
const Login = React.lazy(() => lazyLoader(() => import('./views/site/login/Login')));
const SwitchLogin = React.lazy(() => lazyLoader(() => import('./views/site/login/SwitchLogin')));
const AutoLogin = React.lazy(() => lazyLoader(() => import('./views/site/login/AutoLogin')));
const Register = React.lazy(() => lazyLoader(() => import('./views/site/register/Register')));
const Confirmation = React.lazy(() => lazyLoader(() => import('./views/site/register/Confirmation')));
const ResendConfirmation = React.lazy(() => lazyLoader(() => import('./views/site/register/ResendConfirmation')));
const PasswordRequestReset = React.lazy(() => lazyLoader(() => import('./views/site/login/PasswordRequestReset')));
const PasswordReset = React.lazy(() => lazyLoader(() => import('./views/site/login/PasswordReset')));
const Page401 = React.lazy(() => lazyLoader(() => import('./views/site/page401/Page401')));
const Page404 = React.lazy(() => lazyLoader(() => import('./views/site/page404/Page404')));
const Page500 = React.lazy(() => lazyLoader(() => import('./views/site/page500/Page500')));
const PrintTransaction = React.lazy(() => lazyLoader(() => import('./views/transaction/PrintTransaction')));
const PrintReportDetailStock = React.lazy(() => lazyLoader(() => import('./views/report/detail/PrintReportDetailStock')));
const PrintReportSummaryStock = React.lazy(() => lazyLoader(() => import('./views/report/summary/PrintReportSummaryStock')));
const PrintReportMinimumStock = React.lazy(() => lazyLoader(() => import('./views/report/minimum/PrintReportMinimumStock')));
const PrintReportLocatorMinimumStock = React.lazy(() => lazyLoader(() => import('./views/report/minimum/PrintReportLocatorMinimumStock')));

class App extends Component {
  constructor(props){
    super(props)
    this.state = {
      language: 'id',
      setLanguage: this.setLanguage
    }
  }

  setLanguage = language => {
    storeData('lang', language, (60*24*60));
    this.setState({ language: language.toLowerCase() });
  };

  componentDidMount() {
    if (getData('lang') === false) {
      storeData('lang', 'ID', (60*24*60));
    }

    this.setState({
      language: getData('lang').toLowerCase()
    });

    //console.log('hostname: '+window.location.hostname);
    if (window.location.hostname === process.env.REACT_APP_DOMAIN) {
      if (window.platform.isMobile) {
        //this.props.history.push('/')
      } else {
        this.window.location.href = '//member.'+process.env.REACT_APP_DOMAIN;
      }
    }

    var arrDomain = window.location.hostname.split('.');
    var subdomain = arrDomain[0];

    if (process.env.REACT_APP_SUBDOMAIN !== undefined && process.env.REACT_APP_SUBDOMAIN !== '') {
      subdomain = process.env.REACT_APP_SUBDOMAIN;
    }

    //console.log('subdomain: '+subdomain);
    if (subdomain === '') {
      if (window.platform.isMobile) {
        //this.props.history.push('/')
      } else {
        this.window.location.href = '//member.'+process.env.REACT_APP_DOMAIN;
      }
    } else {
      if (subdomain !== 'member') {
        axios.get(process.env.REACT_APP_API_URL+'/account/subdomain/check/'+subdomain+'?lang='+getData('lang'), {
          auth: {
            username: process.env.REACT_APP_API_CLIENT_ID, password: process.env.REACT_APP_API_CLIENT_SECRET
          }
        }).then(response => {
          //console.debug(response.data)

          if (!response.data.data.exist) {
            if (window.platform.isMobile) {
              this.props.history.push('/404')
            } else {
              window.location.href = '//member.'+process.env.REACT_APP_DOMAIN+'/#/404';
            }
          }
        });
      }
    }
  }

  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return (
            <LanguageContext.Provider value={this.state}>
              <Provider store={store}>
                <HashRouter>
                    <React.Suspense fallback={processing}>
                      <Switch>
                        <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                        <Route exact path="/switch" name="Switch Login" render={props => <SwitchLogin {...props}/>} />
                        <Route exact path="/auto" name="Auto Login" render={props => <AutoLogin {...props}/>} />
                        <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
                        <Route exact path="/confirm/resend" name="Resend Confirmation" render={props => <ResendConfirmation {...props}/>} />
                        <Route exact path="/confirm/:id" name="Confirmation Page" render={props => <Confirmation {...props}/>} />
                        <Route exact path="/password/reset/request" name="Request Reset Password Page" render={props => <PasswordRequestReset {...props}/>} />
                        <Route exact path="/password/reset" name="Reset Password Page" render={props => <PasswordReset {...props}/>} />
                        <Route exact path="/401" name="Page 401" render={props => <Page401 {...props}/>} />
                        <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                        <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                        <Route path="/transaction/:type/:id/print" name="Print Transaction" render={props => <PrintTransaction {...props}/>} />
                        <Route path="/report/print/detail" name="Print Report Detail Stock" render={props => <PrintReportDetailStock {...props}/>} />
                        <Route path="/report/print/summary" name="Print Report Summary Stock" render={props => <PrintReportSummaryStock {...props}/>} />
                        <Route path="/report/print/minimum" exact name="Print Report Minimum Stock" render={props => <PrintReportMinimumStock {...props}/>} />
                        <Route path="/report/print/minimum/locator" exact name="Print Report Locator Minimum Stock" render={props => <PrintReportLocatorMinimumStock {...props}/>} />
                        <Route path="/" name="Home" lang={this.state.language} render={props => <TheLayout {...props}/>} />
                      </Switch>
                    </React.Suspense>
                </HashRouter>
              </Provider>
            </LanguageContext.Provider>
          );
        }}
      </CacheBuster>
    );
  }
}

export default App;
