import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  locatorId: "",
};

const locatorSlice = createSlice({
  name: "locatorId",
  initialState,
  reducers: {
    setLocatorId: (state, action) => {
      state.locatorId = action.payload;
    },
  },
});

export const { setLocatorId } = locatorSlice.actions;

export default locatorSlice.reducer;
