import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  transactionFields: [],
};

const transactionFieldSlice = createSlice({
  name: "transactionFields",
  initialState,
  reducers: {
    clearTransactionFields: (state) => {
      state.transactionFields = [];
    },
    setTransactionFields: (state, action) => {
      state.transactionFields = action.payload.map((item) => {
        return {
          fieldData: item,
          input: {
            value: item.value ? (item.type === 'dropdown'?{id: item.value, name: item.value}:item.value) : "",
          },
        };
      });
    },
    updateInputTransactionFieldData: (state, action) => {
      const { index, fieldName, value } = action.payload;

      const newData = [...state.transactionFields];

      newData[index].input[fieldName] = value;

      state.transactionFields = newData;
    },
  },
});

export const {
  clearTransactionFields,
  setTransactionFields,
  updateInputTransactionFieldData
} = transactionFieldSlice.actions;

export default transactionFieldSlice.reducer;
