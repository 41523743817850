import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './store'

import { App as CapacitorApp } from '@capacitor/app';

import { Toast } from '@capacitor/toast'
import { isScannerRunning, stopScanner } from './utils/common';

React.icons = icons

window.platform = {
  name: window.Capacitor.getPlatform(),
  isWeb: window.Capacitor.getPlatform() === 'web',
  isMobile: window.Capacitor.getPlatform() === 'android' || window.Capacitor.getPlatform() === 'ios',
  isAndroid: window.Capacitor.getPlatform() === 'android',
  isIos: window.Capacitor.getPlatform() === 'ios',
}

const renderReactDom = () => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  );
};

if (window.platform.isMobile) {
  document.addEventListener('deviceready', () => {
    renderReactDom();
  }, false);

  // === Configure back button ===
  let totalBackButtonClicked = 1;
  let lastBackButtonClicked = new Date().valueOf();

  CapacitorApp.addListener('backButton', ({ canGoBack }) => {
    if (isScannerRunning) {
      stopScanner()
      return
    }

    if (canGoBack) {
      window.history.back(-1)
    } else {
      const currentMillis = new Date().valueOf();

      if ((currentMillis - lastBackButtonClicked) < 1000) {
        totalBackButtonClicked++;
      } else {
        totalBackButtonClicked = 1;
      }

      lastBackButtonClicked = currentMillis;

      switch (totalBackButtonClicked) {
        case 2:
          Toast.show({
            text: 'Press again to exit',
            duration: 'short',
            position: 'bottom'
          })
          break;
        case 3:
          CapacitorApp.exitApp();
          break;

        default: break;
      }
    }
  })
} else {
  renderReactDom();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
